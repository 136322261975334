import React from "react";
import { PageWrapper } from "../components/PageWrapper";
import { Paragraph, Container, H2 } from "@wel-shy/portfolio-component-library";
import { Intro } from "../components/Intro";

const NotFound = () => (
  <PageWrapper pageTitle="Not Found">
    <Intro />
    <Container>
      <H2>Not Found</H2>
      <Paragraph>This page could not be found.</Paragraph>
    </Container>
  </PageWrapper>
);

export default NotFound;
